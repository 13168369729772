<template>
    <div>
        <div class="d-flex justify-space-between">
            <div class="d-flex">
                <img style="width: 48px; height: 48px; margin: 0 4px 4px 4px" :src="currency.image"/>
                <h1> {{ currency.name }} Status</h1>
            </div>
            <div class="d-flex align-baseline" style="height: 30px">
                <v-btn-toggle
                    v-model="graphUnit"
                    borderless
                    mandatory
                >
                    <v-btn value="Blocks">
                        <v-icon>
                            fa fa-solid fa-cube
                        </v-icon>
                    </v-btn>

                    <v-btn value="Time">
                        <v-icon>
                            fa-regular fa-clock
                        </v-icon>
                    </v-btn>
                </v-btn-toggle>
            </div>
        </div>
        <v-divider/>
        <v-divider class="mb-3"></v-divider>

        <div style="padding: 0 6px" class="d-flex flex-column">
            <v-row style="gap: 6px">
                <v-col class="pa-0">
                    <FancyCard class="mt-2" style="min-width: 250px; min-height: 205px">
                        <template #header>
                            <div class="d-flex align-center">
                                <v-icon class="d-block mr-2 ml-1" width="22" style="filter: opacity(0.6)">
                                    fa-solid fa-database
                                </v-icon>
                                Blockchain Info
                            </div>
                        </template>
                        <template #body>
                            <NameValueFlex loading-width="100" :loading="loading">
                                <template #name>
                                    Addresses:
                                </template>
                                <template #value>
                                    <span>{{ $humanize.formatNumber(dashboardData.cluster.addresses) }}</span>
                                </template>
                            </nameValueFlex>
                            <NameValueFlex loading-width="100" :loading="loading">
                                <template #name>
                                    <nobr>
                                        Transactions:
                                    </nobr>
                                </template>
                                <template #value>
                                    <span>{{
                                            $humanize.formatNumber(dashboardData.cluster.transactions)
                                        }}</span>
                                </template>
                            </nameValueFlex>
                            <NameValueFlex loading-width="100" :loading="loading">
                                <template #name>
                                    <nobr>
                                        Clusters:
                                    </nobr>
                                </template>
                                <template #value>
                                    <span>{{ $humanize.formatNumber(dashboardData.cluster.clusters) }}</span>
                                </template>
                            </nameValueFlex>
                            <NameValueFlex loading-width="100" :loading="loading" nameWidth="110">
                                <template #name>
                                    Blockchain Size:
                                </template>
                                <template #value>
                                    <span v-if="dashboardData.status.blockchainSize">
                                        {{ $humanize.fileSize(dashboardData.status.blockchainSize) }}
                                    </span>
                                    <span class="textGrey" v-else>N/A</span>
                                </template>
                            </nameValueFlex>
                            <NameValueFlex loading-width="100" :loading="loading" nameWidth="110">
                                <template #name>
                                    Index Size:
                                </template>
                                <template #value>
                                    <span>{{ $humanize.fileSize(dashboardData.status.indexSize) }}</span>
                                </template>
                            </nameValueFlex>
                        </template>
                    </FancyCard>
                </v-col>
                <v-col class="pa-0">
                    <FancyCard class="mt-2" style="min-height: 205px">
                        <template #header>
                            <div class="d-flex align-center">
                                <v-icon class="d-block mr-2 ml-1" width="22" style="filter: opacity(0.6)">
                                    mdi-magnify
                                </v-icon>
                                Crypto-Client Info
                            </div>
                        </template>
                        <template #body>
                            <NameValueFlex :loading="loading" nameWidth="110" loading-width="100">
                                <template #name>
                                    User Agent:
                                </template>
                                <template #value>
                                    <span>{{
                                            dashboardData.currency === "Ethereum" ? dashboardData.status.useragent.slice(0, 4) : dashboardData.status.useragent
                                        }}</span>
                                </template>
                            </nameValueFlex>
                            <NameValueFlex loading-width="100" :loading="loading" nameWidth="110">
                                <template #name>
                                    Connections:
                                </template>
                                <template #value>
                                    <router-link class="link" :to="'/' + currency.unit + '/peers'">
                                        <span>{{ dashboardData.netinfo.connections }} peers</span>
                                    </router-link>
                                </template>
                            </nameValueFlex>
                            <NameValueFlex loading-width="100" :loading="loading">
                                <template #name>
                                    Version:
                                </template>
                                <template #value>
                                    <span v-if="dashboardData.status.version">
                                        {{ dashboardData.status.version }}
                                    </span>
                                    <span class="textGrey" v-else>N/A</span>
                                </template>
                            </nameValueFlex>
                            <NameValueFlex loading-width="100" :loading="loading" nameWidth="110">
                                <template #name>
                                    Protocol Version:
                                </template>
                                <template #value>
                                    <span v-if="dashboardData.status.protocolVersion">
                                        {{ dashboardData.status.protocolVersion }}
                                    </span>
                                    <span class="textGrey" v-else>N/A</span>
                                </template>
                            </nameValueFlex>
                            <NameValueFlex loading-width="100" :loading="loading">
                                <template #name>
                                    Difficulty:
                                </template>
                                <template #value>
                                    <span v-if="Number(dashboardData.status.difficulty)">{{
                                            $humanize.formatNumber(dashboardData.status.difficulty, currency.unit === "BTC" ? 2 : 0)
                                        }}</span>
                                    <span class="textGrey" v-else>N/A</span>
                                </template>
                            </nameValueFlex>
                        </template>
                    </FancyCard>
                </v-col>
                <v-col class="pa-0 flex-grow-0">
                    <GaugeChart
                        :loading="loading"
                        title="Blocks"
                        :value="blocksProgress"
                        :thresholds="dashboardData.thresholds?.blockchain"
                        :blockTime="getBlockTime(blocksProgress)"
                        :graphUnit="graphUnit"
                    >
                        <template #titleIcon>
                            <v-icon class="d-block mr-2 ml-1" style="filter: opacity(0.6); font-size: 20px">
                                fa fa-solid fa-cube
                            </v-icon>
                        </template>
                    </GaugeChart>
                </v-col>
                <v-col class="pa-0 flex-grow-0">
                    <GaugeChart
                        :loading="loading"
                        title="Indexing"
                        :value="indexProgress"
                        :thresholds="dashboardData.thresholds?.indexed"
                        :blockTime="getBlockTime(indexProgress)"
                        :graphUnit="graphUnit"
                    >
                        <template #titleIcon>
                            <v-icon class="d-block mr-2 ml-1" style="filter: opacity(0.6); font-size: 22px">
                                mdi-database-plus-outline
                            </v-icon>
                        </template>
                    </GaugeChart>
                </v-col>
                <v-col class="pa-0 flex-grow-0">
                    <GaugeChart
                        :loading="loading"
                        title="Importing"
                        :value="importProgress"
                        :thresholds="dashboardData.thresholds?.imported"
                        :blockTime="getBlockTime(importProgress)"
                        :graphUnit="graphUnit"
                    >
                        <template #titleIcon>
                            <v-icon class="d-block mr-2 ml-1" style="filter: opacity(0.6); font-size: 22px">
                                mdi mdi-database-import-outline
                            </v-icon>
                        </template>
                    </GaugeChart>
                </v-col>
                <v-col class="pa-0 flex-grow-0" v-if="clusterProgress">
                    <GaugeChart
                        :loading="loading"
                        title="Clustering"
                        :value="clusterProgress"
                        :thresholds="dashboardData.thresholds?.clustered"
                        :blockTime="getBlockTime(clusterProgress)"
                        :graphUnit="graphUnit"
                    >
                        <template #titleIcon>
                            <v-icon left size="20">mdi-hub</v-icon>
                        </template>
                    </GaugeChart>
                </v-col>
            </v-row>
            <v-row style="gap: 6px">
                <v-col class="pa-0">
                    <v-data-table
                        :loading="loading"
                        :headers="tableHeaders"
                        :items="loading ? [] : tableData"
                        hide-default-footer
                        dense
                        class="cardShadow v-sheet--outlined mt-2"
                    >
                        <template #item="{ item }">
                            <tr v-if="item.block_height">
                                <td class="text-left">{{ item.name }}</td>
                                <td class="text-right">
                                    <router-link class="link"
                                                 :to="'/' + currency.unit + '/block/' + item.block_height">
                                        <span>{{ item.block_height | formatNumber }}</span>
                                    </router-link>
                                </td>
                                <td class="text-truncate">{{
                                        moment.unix(item.block_time).format('YYYY-MM-DD HH:mm:ss')
                                    }}
                                </td>
                                <td class="text-right">{{ item.progress }}</td>
                                <td class="text-right">{{ formatTime(item.time_deficit) }}</td>
                                <td class="text-right">{{ Math.abs(item.block_deficit) | formatNumber }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col class="pa-0 flex-grow-0">
                    <GaugeChart
                        :loading="loading"
                        title="Alarm Addresses"
                        :value="alarmProgress"
                        :thresholds="dashboardData.thresholds?.alarm_address"
                        :blockTime="getBlockTime(alarmProgress)"
                        :graphUnit="graphUnit"
                    >
                        <template #titleIcon>
                            <v-icon class="d-block mr-2 ml-1" style="filter: opacity(0.6); font-size: 22px">
                                mdi mdi-alarm
                            </v-icon>
                        </template>
                    </GaugeChart>
                </v-col>
                <v-col class="pa-0 flex-grow-0">
                    <GaugeChart
                        :loading="loading"
                        title="Alarm Custom Clusters"
                        :value="alarmCustomClusterProgress"
                        :thresholds="dashboardData.thresholds?.alarm_cluster_custom"
                        :blockTime="getBlockTime(alarmCustomClusterProgress)"
                        :graphUnit="graphUnit"
                    >
                        <template #titleIcon>
                            <v-icon class="d-block mr-2 ml-1" style="filter: opacity(0.6); font-size: 22px">
                                mdi mdi-alarm
                            </v-icon>
                        </template>
                    </GaugeChart>
                </v-col>
                <v-col class="pa-0 flex-grow-0" v-if="alarmCospentClusterProgress">
                    <GaugeChart
                        :loading="loading"
                        title="Alarm Co-Spent Clusters"
                        :value="alarmCospentClusterProgress"
                        :thresholds="dashboardData.thresholds?.alarm_cluster_cospent"
                        :blockTime="getBlockTime(alarmCospentClusterProgress)"
                        :graphUnit="graphUnit"
                    >
                        <template #titleIcon>
                            <v-icon class="d-block mr-2 ml-1" style="filter: opacity(0.6); font-size: 22px">
                                mdi mdi-alarm
                            </v-icon>
                        </template>
                    </GaugeChart>
                </v-col>
            </v-row>
            <v-row class="pa-0">
                <LatestTransactions
                    :showInputsOutputs="true"
                    :showFee="true"
                    :transactionIdLength="50"
                />
            </v-row>
        </div>
    </div>
</template>

<script setup>
import FancyCard from "@/components/common/Cards/FancyCard";
import NameValueFlex from "@/components/common/NameValueFlex";
import Vue, {computed, ref, watch} from "vue"
import store from "@/store"
import GaugeChart from "@/components/chart/CurrencySummaryGaugeChart"
import LatestTransactions from "@/components/currency/LatestTransactions";
import moment from "moment";

const graphUnit = ref("Blocks")

const currency = computed(() => store.getters.currency)
const loading = computed(() => store.getters.dashboardLoading[currency.value.unit])

watch(currency, (newValue) => {
    store.dispatch("loadDashboardSingle", {currency: newValue.unit})
})

const dashboardData = computed(() => store.getters.dashboardData[currency.value.unit])

if (!dashboardData.value) {
    store.dispatch('loadDashboardSingle', {currency: currency.value.unit})
}

const blockTimes = computed(() => dashboardData.value.blocks_to_blocktime)

const getBlockTime = (blockHeight) => {
    return Vue.filter('getBlockTimeFromBlockTimesObject')(blockHeight, blockTimes.value)
}

const formatTime = (timeInSeconds => {
    const { value, unit } = Vue.filter('roundTimeToHighestTimeUnit')(timeInSeconds)
    return `${value} ${unit}`
})

const blocksProgress = computed(() => {
    return dashboardData.value.status?.blocks
})

const indexProgress = computed(() => {
    return dashboardData.value.status?.indexHeight
})

const importProgress = computed(() => {
    return dashboardData.value.cluster?.blocks
})

const clusterProgress = computed(() => {
    return dashboardData.value.cluster?.clustered
})

const alarmProgress = computed(() => {
    return dashboardData.value.alarm?.address
})

const alarmCospentClusterProgress = computed(() => {
    return dashboardData.value.alarm?.cluster_cospent
})

const alarmCustomClusterProgress = computed(() => {
    return dashboardData.value.alarm?.cluster_custom
})

const tableHeaders = [
    {text: 'System Module', align: 'left', sortable: false, width: 200},
    {text: 'Block Height', align: 'left', sortable: false, width: 105},
    {text: 'Block Time', align: 'center', sortable: false},
    {text: 'Progress', align: 'right', sortable: false, width: 100},
    {text: 'Time Lag', align: 'center', sortable: false, width: 120},
    {text: 'Blocks Lag', align: 'right', sortable: false, width: 100},
]

const headers = computed(() => dashboardData.value.status?.headers)

const getProgress = (progressValue => (progressValue / headers.value) === 1 ? "Up to date" : `${Math.floor((progressValue / headers.value) * 10000) / 100}%`)

const tableData = computed(() => {
    return [
        {
            name: "Blocks",
            block_height: blocksProgress.value,
            block_time: getBlockTime(blocksProgress.value),
            progress: getProgress(blocksProgress.value),
            time_deficit: moment().unix() - getBlockTime(blocksProgress.value),
            block_deficit: blocksProgress.value - headers.value,
        },
        {
            name: "Indexing",
            block_height: indexProgress.value,
            block_time: getBlockTime(indexProgress.value),
            progress: getProgress(indexProgress.value),
            time_deficit: moment().unix() - getBlockTime(indexProgress.value),
            block_deficit: indexProgress.value - headers.value,
        },
        {
            name: "Importing",
            block_height: importProgress.value,
            block_time: getBlockTime(importProgress.value),
            progress: getProgress(importProgress.value),
            time_deficit: moment().unix() - getBlockTime(importProgress.value),
            block_deficit: importProgress.value - headers.value,
        },
        {
            name: "Clustering",
            block_height: clusterProgress.value,
            block_time: getBlockTime(clusterProgress.value),
            progress: getProgress(clusterProgress.value),
            time_deficit: moment().unix() - getBlockTime(clusterProgress.value),
            block_deficit: clusterProgress.value - headers.value,
        },
        {
            name: "Alarm Addresses",
            block_height: alarmProgress.value,
            block_time: getBlockTime(alarmProgress.value),
            progress: getProgress(alarmProgress.value),
            time_deficit: moment().unix() - getBlockTime(alarmProgress.value),
            block_deficit: alarmProgress.value - headers.value,
        },
        {
            name: "Alarm Custom Clusters",
            block_height: alarmCustomClusterProgress.value,
            block_time: getBlockTime(alarmCustomClusterProgress.value),
            progress: getProgress(alarmCustomClusterProgress.value),
            time_deficit: moment().unix() - getBlockTime(alarmCustomClusterProgress.value),
            block_deficit: alarmCustomClusterProgress.value - headers.value,
        },
        {
            name: "Alarm Cospent Clusters",
            block_height: alarmCospentClusterProgress.value,
            block_time: getBlockTime(alarmCospentClusterProgress.value),
            progress: getProgress(alarmCospentClusterProgress.value),
            time_deficit: moment().unix() - getBlockTime(alarmCospentClusterProgress.value),
            block_deficit: alarmCospentClusterProgress.value - headers.value,
        },
    ]
})

</script>

<style scoped>
.borderRight {
    border-right: 1px solid rgba(0, 0, 0, .12);
}

</style>