import { GET, POST, DELETE, PUT } from "@/services/apiClient"
import axios from "axios";

import {modalState} from "@/components/common/types/modal";


const state = {
    owners: [],
    isOwnersLoading: false,
    ownersCancelToken: null,
    ownersModalState: modalState.Initial,
}

const getters = {
    owners: (state) => state.owners,
    isOwnersLoading: (state) => state.isOwnersLoading,
    ownersModalState: (state) => state.ownersModalState,
}

const mutations = {
    OWNERS_SUGGESTIONS_LOADED(state, { owners }) {
        state.owners = owners
    },
    OWNERS_START_LOADING(state) {
        if(state.ownersCancelToken) {
            state.ownersCancelToken.cancel()
        }
        state.ownersCancelToken = axios.CancelToken.source()
        state.isOwnersLoading = true
    },
    OWNERS_FINISH_LOADING(state) {
        state.isOwnersLoading = false
    },
    OWNERS_MODAL_SET_STATE(state,data) {
        state.ownersModalState = data
    }
}

const actions = {
    async loadOwners({ commit, dispatch }, { pagination, filter, details = "full" }) {
        commit('OWNERS_START_LOADING')
        try {
            const result = await GET("owners", {
                params: { ...pagination, details, filter },
                cancelToken: state.ownersCancelToken.token
            })
            commit('OWNERS_SUGGESTIONS_LOADED', { owners: result })
            commit('OWNERS_FINISH_LOADING')
        } catch (error) {
            if(axios.isCancel(error)) {
                //request is canceled, do nothing
            } else {
                dispatch("error", error.userFriendlyMessage)
                commit('OWNERS_FINISH_LOADING')
            }
        }
    },
    async createOwner({ commit, dispatch }, { ownerDetail }) {
        commit('OWNERS_START_LOADING')
        commit('OWNERS_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await POST("owners", ownerDetail);
            commit('OWNERS_MODAL_SET_STATE', modalState.Success)
            dispatch('success', response.message)
        }
        catch (error) {
            commit('OWNERS_MODAL_SET_STATE', modalState.Error)
            dispatch("error", error.userFriendlyMessage)
        }
        finally {
            commit("OWNERS_FINISH_LOADING")
        }
    },
    async editOwner({ commit, dispatch }, { ownerDetail }) {
        commit('OWNERS_START_LOADING')
        commit('OWNERS_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await PUT(`owners/${ownerDetail.id}`, ownerDetail);
            dispatch('success', response.message)
            commit('OWNERS_MODAL_SET_STATE', modalState.Success)
        }
        catch (error) {
            commit('OWNERS_MODAL_SET_STATE', modalState.Error)
            dispatch("error", error.userFriendlyMessage)
        }
        finally {
            commit("OWNERS_FINISH_LOADING")
        }
    },
    async deleteOwner({ commit, dispatch }, { ownerId }) {
        commit('OWNERS_START_LOADING')

        try {
            const response = await DELETE(`owners/${ownerId}`)
            if (response) {
                this.dispatch('success', response.message)
            }
        }
        catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        finally {
            commit("OWNERS_FINISH_LOADING")
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
