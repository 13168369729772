<template>
    <div class="scroll-y height100">
        <v-container>
            <div v-if="loading">
                <ProgressCircular style="padding-top: 200px;"/>
            </div>
            <v-layout v-else-if="!results" align-center fill-height justify-center>
                <h2 class="font-weight-light">Can not found any results for {{ $route.query.q }}</h2>
            </v-layout>
            <div v-else>
                <h1>Search Results</h1>
                <v-divider/>
                <v-card class="transparent border mt-3" elevation="0">
                    <v-tabs
                        slider-size="5"
                        v-model="selectedTab"
                        :background-color="currency ? currency.unit  + '': 'secondary'"
                        dark
                        slider-color="white"
                    >
                        <v-tab v-for="category in categories" :key="category.title" class="mx-2">
                            <v-badge color="red">
                                <span slot="badge">{{ results[category.key].length }}</span>
                                {{ category.title }}
                            </v-badge>
                        </v-tab>

                        <v-tab-item>
                            <InfoText>
                                Addresses successfully parsed as proper ones supported
                                system (e.g., Bitcoin) based on
                                regular expression.
                            </InfoText>
                            <v-card text>
                                <v-data-table
                                    :headers="validHeaders"
                                    :items="results.valid"
                                    :items-per-page="-1"
                                    class="elevation-0"
                                    hide-default-footer
                                >
                                    <template slot="item" slot-scope="props">
                                        <tr>
                                            <td class="text-left">
                                                <RouteToAddress :address="props.item.address"
                                                                :currency="props.item.crypto">
                                                    <template #text>
                                                        {{ props.item.address }}
                                                    </template>
                                                </RouteToAddress>
                                            </td>
                                            <td>
                                                <img
                                                    :src="('cryptoIcons/color/' + props.item.crypto.toLowerCase() + '.svg') | createImageUrl"
                                                    class="middle mr-2"
                                                />
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item>
                            <InfoText>
                                Valid addresses that have some transactions in the blockchain, beaware of forked
                                projects
                                (e.g., Bitcoin and Bitcoincash)
                            </InfoText>
                            <v-card text>
                                <v-data-table
                                    :headers="recognizedHeaders"
                                    :items="results.recognized"
                                    :items-per-page="-1"
                                    class="elevation-0"
                                    hide-default-footer
                                >
                                    <template slot="item" slot-scope="props">
                                        <tr>
                                            <td class="text-left">
                                                <RouteToAddress :address="props.item.address"
                                                                :currency="props.item.crypto">
                                                    <template #text>
                                                        {{ props.item.address }}
                                                    </template>
                                                </RouteToAddress>
                                            </td>
                                            <td class="text-overflow">
                                                <BlockchainCryptoBadge
                                                    :crypto="props.item.crypto"
                                                    :currencies="currencies"
                                                />
                                            </td>
                                            <td>
                                                <fiat-convertor :fiats="results.fiats[props.item.crypto]"
                                                                :value="props.item.balance"
                                                                monospace
                                                                with-unit/>
                                            </td>
                                            <td class="fontMonospace text-right">{{ props.item.txcount }}</td>
                                            <td>
                                                <router-link
                                                    :to="`/${props.item.crypto}/transaction/${props.item.lasttxid}/`"
                                                    class="link"
                                                >{{ props.item.lastblocktime | moment('YYYY-MM-DD HH:mm:ss Z') }}
                                                </router-link>
                                            </td>
                                            <td>
                                                <router-link
                                                    :to="`/${props.item.crypto}/block/${props.item.lastblockheight}/`"
                                                    class="link fontMonospace"
                                                >{{ props.item.lastblockheight | formatNumber }}
                                                </router-link>
                                            </td>
                                            <td class="text-xs-right">{{ props.item.identity }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item>
                            <InfoText>
                                Valid addresses which do not have any transactions in any blockchain (i.e., unused
                                addresses)
                            </InfoText>
                            <v-card text>
                                <v-data-table
                                    :headers="validHeaders"
                                    :items="results.unrecognized"
                                    :items-per-page="-1"
                                    class="elevation-0"
                                    hide-default-footer
                                >
                                    <template slot="item" slot-scope="props">
                                        <tr>
                                            <td>
                                                <RouteToAddress :address="props.item.address"
                                                                :currency="props.item.crypto">
                                                    <template #text>
                                                        {{ props.item.address }}
                                                    </template>
                                                </RouteToAddress>
                                            </td>
                                            <td>
                                                <img
                                                    :src="('cryptoIcons/color/' + props.item.crypto.toLowerCase() + '.svg') | createImageUrl"
                                                    class="middle mr-2"
                                                />
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item>
                            <InfoText>
                                Addresses unsuccessfully matched against regular expressions present in our system,
                                maybe
                                from other cryptocurrencies
                            </InfoText>
                            <v-card text>
                                <v-data-table
                                    :headers="invalidHeaders"
                                    :items="results.invalid"
                                    :items-per-page="-1"
                                    class="elevation-0"
                                    hide-default-footer
                                >
                                    <template slot="item" slot-scope="props">
                                        <tr>
                                            <td>{{ props.item.address }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <InfoText>
                                All inputs passed to search textarea field
                            </InfoText>
                            <v-card text>
                                <v-data-table
                                    :headers="allinputHeaders"
                                    :items="results.addrs"
                                    :items-per-page="-1"
                                    class="elevation-0"
                                    hide-default-footer
                                >
                                    <template slot="item" slot-scope="props">
                                        <tr>
                                            <td>{{ props.item }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                </v-card>
            </div>
        </v-container>
    </div>
</template>

<script>
import ProgressCircular from '@/components/common/ProgressCircular'
import BlockchainCryptoBadge from '@/components/common/BlockchainCryptoBadge'
import {mapGetters} from "vuex";
import FiatConvertor from "@/components/common/FiatConvertor";
import RouteToAddress from "@/components/common/RouteToAddress.vue";
import InfoText from "@/components/common/InfoText.vue";

export default {
    name: 'SearchView',
    components: {
        InfoText,
        RouteToAddress,
        FiatConvertor,
        ProgressCircular,
        BlockchainCryptoBadge
    },
    mounted() {
        if (this.$route.query.q) {
            this.$store.dispatch('search', this.$route.query.q);
        }
    },
    computed: {
        ...mapGetters(["currencies"]),
        results() {
            return this.$store.getters.searchResults
        },
        loading() {
            return this.$store.getters.searchLoading
        },
        currency() {
            return this.$store.getters.currency
        }
    },
    data() {
        return {
            selectedTab: 1,
            recognizedHeaders: [
                {text: 'Address', align: 'left', value: 'address'},
                {text: 'Currency', align: 'center', value: 'currency', sortable: true},
                {text: 'Balance', align: 'right', value: 'balance', sortable: true},
                {text: 'Transactions', align: 'right', value: 'tx_count', sortable: true},
                {text: 'Last transaction', align: 'center', value: 'lasttxid', sortable: true},
                {text: 'Last block', align: 'center', value: 'lastblocktime', sortable: true},
                {text: 'Identity', align: 'center', value: 'identity', sortable: true}
            ],
            validHeaders: [
                {text: 'Address', align: 'left', value: 'address'},
                {text: 'Currency', value: 'currency'}
            ],
            invalidHeaders: [
                {text: 'Input', align: 'left', value: 'address'}
            ],
            allinputHeaders: [
                {text: 'Input', align: 'left', value: 'addrs', sortable: false}
            ],
            categories: [
                {'title': 'Valid', 'key': 'valid'},
                {'title': 'Recognized', 'key': 'recognized'},
                {'title': 'Unrecognized', 'key': 'unrecognized'},
                {'title': 'Invalid', 'key': 'invalid'},
                {'title': 'All', 'key': 'addrs'}
            ]
        }
    }
}
</script>
