var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalDialog", {
    attrs: {
      title: "Edit All Cluster Metadata",
      loading: _vm.loading,
      width: "60%",
    },
    scopedSlots: _vm._u(
      [
        {
          key: "showbutton",
          fn: function ({ on }) {
            return [_vm._t("showbutton", null, { on: on })]
          },
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "ValidationObserver",
                { ref: "validator", attrs: { mode: "lazy" } },
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "0" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-2", attrs: { cols: "4" } },
                            [
                              _c("v-text-field-validateable", {
                                attrs: {
                                  disabled: "",
                                  rules: "required|crypto",
                                  label: "Entity ID",
                                  outlined: "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.clusterMetadataDetail.cluster,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clusterMetadataDetail,
                                      "cluster",
                                      $$v
                                    )
                                  },
                                  expression: "clusterMetadataDetail.cluster",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "py-2", attrs: { cols: "3" } },
                            [
                              _c("BlockchainCryptoPicker", {
                                attrs: {
                                  disabled: "",
                                  currencies: _vm.currencies,
                                  value: _vm.crypto,
                                  rules: "required",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.crypto,
                                  callback: function ($$v) {
                                    _vm.crypto = $$v
                                  },
                                  expression: "crypto",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "py-2", attrs: { cols: "2" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  label: "Number of Addresses",
                                  value: _vm._f("formatNumber")(
                                    _vm.clusterMetadataDetail.totalAddresses
                                  ),
                                  outlined: "",
                                  "hide-details": "",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "py-2", attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  label: "Created At",
                                  value: _vm._f("moment")(
                                    _vm.clusterMetadataDetail.created_at,
                                    "YYYY-MM-DD HH:mm:ss Z"
                                  ),
                                  outlined: "",
                                  "hide-details": "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "ma-2" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-2" },
                            [
                              _c("CategoryPicker", {
                                attrs: {
                                  categories: _vm.categories,
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.clusterMetadataDetail.categoryIds,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clusterMetadataDetail,
                                      "categoryIds",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clusterMetadataDetail.categoryIds",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-2",
                              attrs: { id: "disabled-owner-text-field" },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  disabled: "",
                                  label: "Owners",
                                  "item-text": "name",
                                  "item-value": "id",
                                  items: _vm.clusterMetadataDetail.owners,
                                  outlined: "",
                                  multiple: "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.clusterMetadataDetail.owners,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clusterMetadataDetail,
                                      "owners",
                                      $$v
                                    )
                                  },
                                  expression: "clusterMetadataDetail.owners",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "py-2" },
                            [
                              _c("OwnerPicker", {
                                attrs: {
                                  owners: _vm.owners,
                                  loading: _vm.isOwnersLoading,
                                  outlined: "",
                                  "hide-details": "",
                                },
                                on: {
                                  "owner-filter-changed-event":
                                    _vm.ownerFilterChanged,
                                },
                                model: {
                                  value: _vm.clusterMetadataDetail.owners,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clusterMetadataDetail,
                                      "owners",
                                      $$v
                                    )
                                  },
                                  expression: "clusterMetadataDetail.owners",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function ({ close }) {
            return [
              _c(
                "v-btn",
                { attrs: { color: "error", text: "" }, on: { click: close } },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.save(close)
                    },
                  },
                },
                [_vm._v(_vm._s("Save Cluster Metadata"))]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }