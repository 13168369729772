<template>
    <v-app>
        <v-main class="height100">
            <v-app-bar
                v-if="user"
                :color="currency ? currency.unit : 'secondary'"
                app
                class="topZIndex"
                dark
                fixed
                height="68"
                text
            >
                <v-toolbar-title class="mr-6 ml-5 mt-n1">
                    <router-link class="logo" to="/">
                        <v-img v-if="settings.app.brand_type === 'logo'" :src="'/img/logos/' + settingsApp.logo"
                               class="mx-4" min-width="140px"
                               width="140px"></v-img>
                        <div v-else-if="settings.app.brand_type === 'text' && !settings.app.by_logo">
                            <span>{{ this.settingsApp.name }}</span>
                        </div>
                        <div v-else style="color: white">
                            <p class="text-h5 mb-n1 font-weight-bold" style="font-size: 1.9rem !important;">
                                {{
                                    settings.app.name
                                }}
                            </p>
                            <div class="img-container">
                                <img src="/img/logos/by_netsearch.svg" style="width: 100px;">
                            </div>
                        </div>
                    </router-link>
                </v-toolbar-title>

                <ToolbarSearch/>

                <v-spacer/>

                <div class="d-flex align-center mx-2">
                    <v-select
                        v-if="debug"
                        v-model="roleOverride"
                        :items="['superadmin','admin','hiddenreporter','reporter','user']"
                        dense
                        hide-details
                        hide-selected
                        outlined
                        style="width: 180px"/>
                </div>

                <div class="mr-3">
                    <div class="d-flex">
                        <FiatPicker/>
                    </div>
                </div>

                <OptionsMenu/>

            </v-app-bar>
            <div class="d-flex overflow-y-hidden" style="width: 100%">
                <SideBarMenu v-if="user"/>
                <div style="height: calc(100vh - 68px); width: 100%; overflow-y: auto; position: relative">
                    <router-view/>
                </div>
            </div>
        </v-main>
        <v-snackbars :objects.sync="notifications" content-class="text-pre-line">
            <template #default="{ message }">
                <h3 v-if="message.type" class="mb-2">{{ message.type }}</h3>
                <Copyable v-if="message?.message" justify="start" dark :on-copy="message.message" container-width="100%">
                    <template #text>
                        <div class="snackbar-text-container monospace">{{ message.message }}</div>
                    </template>
                </Copyable>
            </template>
        </v-snackbars>
        <TestEnvironmentWarningModal v-if="user"/>
    </v-app>
</template>

<script>
import ToolbarSearch from '@/components/search/ToolbarSearch'
import {mapGetters} from "vuex";
import FiatPicker from "@/components/currency/FiatPicker";
import VSnackbars from "v-snackbars";
import OptionsMenu from "@/components/user/OptionsMenu.vue";
import SideBarMenu from "@/components/SideBarMenu.vue";
import store from "@/store";
import Copyable from "@/components/common/Copyable.vue";
import TestEnvironmentWarningModal from "@/components/user/TestEnvironmentWarningModal.vue";

export default {
    components: {
        TestEnvironmentWarningModal,
        Copyable,
        SideBarMenu,
        OptionsMenu,
        ToolbarSearch,
        FiatPicker,
        "v-snackbars": VSnackbars,
    },
    computed: {
        ...mapGetters(["currency", "dashboardData", "currencies", "settings", "user", "debug"]),
        notifications: {
            get: function () {
                return this.$store.getters.notifications
            },
            set: function (newValue) {
                this.$store.commit('SET_NOTIFICATIONS', newValue)
            }
        },
        nonCrypto() {
            return this.$store.getters.nonCryptoPrices
        },
        msg() {
            return this.$store.getters.msg
        },
        color() {
            return this.$store.getters.color
        },
        user() {
            return this.$store.getters.user
        },
        currencies() {
            return this.$store.getters.currencies
        },
        enabledCurrencies() {
            return this.$store.getters.enabledCurrencies
        },
        currency() {
            return this.$store.getters.currency
        },
        settingsApp() {
            return this.settings.app
        },
        roleOverride: {
            get() {
                return this.user.roles[0].name.toLowerCase()
            },
            set(val) {
                store.commit('USER_SET_ROLE_NAME', val)
            }
        }
    },
    methods: {
        toSummary() {
            if (this.$route.name !== this.$route.name) this.$router.push({name: this.$route.name})

        },
        toggleNonCrypto() {
            this.$store.dispatch('showNonCryptoPrices', !this.nonCrypto)
        },
        updateCombobox() {
            if (this.current) {
                this.$router.push({path: `/${this.current}`})
            } else {
                this.$router.push("/")
            }
        }
    },
    data() {
        return {
            menuVisible: false,
            current: '',
        }
    },
    watch: {
        $route: function (to, from) {
            this.menuVisible = false
            if (this.$route.params.currency) {
                this.current = this.$route.params.currency
            } else {
                this.current = ''
            }
        },
    },
    name: 'App'
}
</script>
<style scoped>
.logo {
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 1.5px;
    text-decoration: none;
    color: white;
}

.topZIndex {
    z-index: 9999;
}

header.v-app-bar.v-app-bar--fixed {
    z-index: 9999;
}

.currencyImg {
    vertical-align: middle;
}

.miniDrawer .v-list {
    width: 100%;
}

.column-drawer {
    position: absolute;
    right: 0;
    height: 100%;
    width: 4px;
}

.v-text-field__details {
    position: absolute !important;
    z-index: -1 !important;
}

.img-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
}

</style>

<style>

.snackbar-text-container {
    max-height: 30vh;
    overflow: auto;
    width: 100%;
    overflow-wrap: anywhere;
}


.appCryptoPicker .v-input__slot > fieldset {
    max-height: 56px !important;
}

</style>
