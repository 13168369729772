<script setup>
import GenericPicker from "@/components/common/Pickers/GenericPicker.vue";

const props = defineProps({
    value: {
        type: Array,
        required: true
    },
    chips: {
        type: Boolean,
        required: false,
        default: false
    },
    table: {
        type: Boolean,
        required: false,
        default: false
    },
    item_id: {
        type: String,
        required: false
    },
    headers: {
        type: Array,
        required: false
    },
    getItems: {
        type: Function,
        required: true
    },
    items: {
        type: Array,
        required: true
    },
    loading: {
        type: Boolean,
        default: false,
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    itemValue: {
        type: String,
        required: true
    },
    prependIcon: {
        type: String,
        required: false
    }
})

const emit = defineEmits(['input'])

const removeItem = (itemToRemove) => {
    const itemId = itemToRemove[props.item_id]
    emit('input', props.value.filter(c => c[props.item_id] !== itemId))
}

const tableHeaders = props.headers.concat([{
    text: '',
    align: 'end',
    value: '',
    sortable: false,
    filterable: false,
    width: 42
}])

</script>

<template>
    <div>
        <div v-if="chips" style="min-height: 24px">
            <v-chip
                v-for="(item, index) in value"
                :key="index"
                class="ma-1"
                close
                close-icon="mdi-delete"
                label
                small
                @click:close="removeItem(item)"
            >
                <slot :item="item" name="chipItem"/>
            </v-chip>
        </div>
        <GenericPicker
            :get-items="getItems"
            :item-value="itemValue"
            :items="items"
            :loading="loading"
            :multiple="multiple"
            :value="value"
            @input="emit('input', $event)">
            <template #item="{ item }">
                <slot :item="item" name="pickerItem"/>
            </template>
            <template #hint>
                <slot name="hint"/>
            </template>
            <template #selection="{ item }">
                <slot :item="item" name="selectionItem"/>
            </template>
        </GenericPicker>
        <div v-if="table" class="my-1">
            Selected items:
        </div>
        <div v-if="table" class="mt-2">
            <v-data-table
                :headers="tableHeaders"
                :items="value"
                :items-per-page="-1"
                dense
                height="300px"
                hide-default-footer
                no-data-text="No clusters selected!">
                <template #item="{ item, index }">
                    <v-hover v-slot="{ hover }">
                        <tr class="noselect" style="cursor: pointer" @click="() => removeItem(value[index])">
                            <slot :item="item" name="tableItem"/>
                            <td>
                                <div style="width: 32px; min-width: 32px">
                                    <v-icon v-if="hover" color="error" small>
                                        mdi-delete
                                    </v-icon>
                                </div>
                            </td>
                        </tr>
                    </v-hover>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<style scoped>

</style>