var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex", staticStyle: { width: "100%" } },
    [
      _c("div", { staticClass: "text-truncate" }, [
        _vm._v("\n        " + _vm._s(_vm.item.title) + "\n    "),
      ]),
      _vm._v("\n    |\n    "),
      _c("v-icon", { staticClass: "mx-1", attrs: { "x-small": "" } }, [
        _vm._v("\n        mdi-file-multiple\n    "),
      ]),
      _vm._v(
        "\n    " +
          _vm._s(_vm._f("numberFormatCompact")(_vm.item.files_count)) +
          "\n    |\n    "
      ),
      _c("v-icon", { staticClass: "mx-1", attrs: { "x-small": "" } }, [
        _vm._v("\n        mdi-chart-timeline-variant\n    "),
      ]),
      _vm._v("\n    " + _vm._s(_vm.item.graphs_count) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }