<template>
    <BaseModalDialog
        width="80%"
        ref="BaseModalDialogRef"
        max-width="1200px"
        :title="newAlarmForAddress ? 'Create New Alarm' : 'Alarm Detail'"
        @load-modal-state-event="loadState"
        @destroy-modal-state-event="destroyState"

    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>

        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <div class="d-flex justify-center align-end">
                    <div style="width: 100%; max-width: 1150px">
                        <div class="d-flex justify-space-between align-end">
                            <v-text-field
                                :rules="nameRules"
                                style="max-width: 400px"
                                placeholder="Enter name for new alarm!"
                                v-model="alarmModel.name"
                                label="Alarm name"
                                outlined
                                :clearable="detailsEditable"
                                :readonly="!detailsEditable"
                                autofocus
                                shaped
                                validate-on-blur
                            ></v-text-field>
                            <div class="mb-2" v-if="existingAlarm">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-on="on" icon :disabled="detailsEditable" color="info" @click="detailsEditable = true">
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit Alarm</span>
                                </v-tooltip>

                                <TableDeleteModalDialog
                                    :description="`Do you really want to delete alarm ${alarmModel.name} ?`"
                                    :itemToDelete="alarmModel"
                                    @delete-event="deleteAlarm"
                                    tooltip="Delete Alarm"
                                    large
                                />
                            </div>
                        </div>

                        <v-card elevation="0" style="width: 1150px">
                            <v-row no-gutters>
                                <v-col>
                                    <v-list class="pa-0">
                                        <div>
                                            <v-row no-gutters>
                                                <v-col class="mx-2 mt-2">
                                                    <blockchain-crypto-picker
                                                        rules="required"
                                                        :is-clearable="false"
                                                        :currencies="currencies"
                                                        v-model="alarmModel.crypto"
                                                        :disabled="!headerEditable"
                                                        :error="currencyNotSet"
                                                        dense
                                                    />
                                                </v-col>
                                                <v-col>
                                                    <v-tooltip :disabled="!!alarmModel.crypto" top open-delay="200">
                                                        <template #activator="{ on }">
                                                            <div v-on="on">
                                                                <v-select
                                                                    :disabled="!alarmModel.crypto"
                                                                    class="ma-2"
                                                                    :items="entityTypes"
                                                                    v-model="entityType"
                                                                    @change="handleEntityTypeChange"
                                                                    outlined
                                                                    dense
                                                                    label="Alarm Type"
                                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                                    :readonly="!headerEditable"
                                                                />
                                                            </div>
                                                        </template>
                                                        <span>Choose crypto first.</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>

                                            <v-row no-gutters>
                                                <v-col class="mx-2">
                                                    <div v-if="headerEditable" >
                                                        <v-text-field-validateable
                                                            v-if="entityType === 'Address'"
                                                            class="mt-4 mb-n2"
                                                            rules="required|crypto"
                                                            v-model="alarmModel.hash"
                                                            outlined
                                                            style="width: 350px"
                                                            label="Address"
                                                            clearable
                                                            prepend-inner-icon="mdi-magnify"
                                                        />
                                                        <ClusterPicker
                                                            v-else
                                                            ref="ClusterPickerRef"
                                                            margin-top="4px"
                                                            :multiple="false"
                                                            outlined
                                                            :disabled="!alarmModel.crypto"
                                                            :currency-unit="alarmModel.crypto"
                                                            :value="pickedCluster"
                                                            @input="handleClusterChange"
                                                        />
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <v-list-item v-if="!headerEditable" dense>
                                            <v-list-item-action class="font-weight-bold">{{ `${isAlarmTypeAddress ? "Address" : "Cluster"} being observed: ` }}</v-list-item-action>
                                            <v-list-item-content>
                                                <EntityLabel
                                                    :entity="alarmModel.hash"
                                                    :is-address="isAlarmTypeAddress"
                                                    :currency="alarmModel.crypto"
                                                    justify="end"
                                                    :fixed-width="false"
                                                    act-as-link
                                                    is-copyable
                                                />
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item dense>
                                            <v-list-item-action class="font-weight-bold">Created:</v-list-item-action>
                                            <v-list-item-content class="text-right grey--text">
                                                <v-list-item-title>{{ alarmModel.created_at | moment('YYYY-MM-DD HH:mm:ss Z') }}&nbsp;{{createdElapsed}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item dense class="mt-n4">
                                            <v-list-item-action class="font-weight-bold">Updated:</v-list-item-action>
                                            <v-list-item-content class="text-right grey--text">
                                                <v-list-item-title>{{alarmModel.updated_at | moment('YYYY-MM-DD HH:mm:ss Z')}}&nbsp;{{updatedElapsed}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col>
                                    <v-list class="pt-6">
                                        <v-list-item class="d-flex" style="bottom: 12px">
                                            <v-list-item-action class="font-weight-bold">Tx direction:</v-list-item-action>
                                            <v-spacer/>
                                            <TypePicker v-model="alarmModel.tx_direction" :readonly="!detailsEditable" :color="detailsEditable ? 'blue' : 'primary'"/>
                                        </v-list-item>
                                        <v-list-item class="d-flex mb-4">
                                            <v-list-item-action class="font-weight-bold">Notify:</v-list-item-action>
                                            <v-spacer/>
                                            <NotificationPicker v-model="alarmModel.notify" :readonly="!detailsEditable" :color="detailsEditable ? 'blue' : 'primary'"/>
                                        </v-list-item>
                                        <v-divider />
                                        <v-list-item >
                                            <v-list-item-action class="font-weight-bold">Owner:</v-list-item-action>
                                            <v-list-item-content class="text-right">
                                                <v-list-item-title>{{alarmModel.username}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>

                        </v-card>
                    </div>
                </div>
                <div class="d-flex justify-center">
                    <div style="width: 1150px">
                        <v-textarea
                            class="mb-0 ma-1 mt-3"
                            hide-details
                            outlined
                            label="Additional notes"
                            v-model="alarmModel.email_template"
                            :readonly="!detailsEditable"
                        />
                    </div>
                </div>

                <div v-if="existingAlarm">
                    <h1 class="mt-4">Alarm notifications ({{alarm.notifications_count}} in total)</h1>
                    <div class="d-flex justify-center">
                        <div style="max-width: 1150px; width: 100%">
                            <v-data-table
                                :headers="headers"
                                :items="computedNotificationsData"
                                :options.sync="pagination"
                                :server-items-length="notifications.total"
                                :loading="notificationsLoading"
                                class="elevation-0 border mt-2"
                                dense
                                @update:items-per-page="itemsPerPageUpdated"
                                @update:page="pageUpdated"
                                :footer-props="{
                                    'show-current-page': true,
                                    'showFirstLastPage': true,
                                }"
                            >
                                <template slot="item" slot-scope="props">
                                    <tr>
                                        <td class="text-overflow">{{ props.item.created_at | moment('YYYY-MM-DD HH:mm:ss Z')}}&nbsp;({{props.item.elapsedCreated}})</td>
                                        <td>
                                            <router-link
                                                class="link"
                                                :to="{ name: 'TransactionDetail', params:{ currency : alarmModel.crypto , transaction: props.item.tx_hash}}"
                                            >
                                                <span class="fontMonospace">
                                                    {{props.item.tx_hash}}
                                                </span>
                                            </router-link>
                                        </td>
                                        <td class="text-overflow">
                                            <router-link
                                                class="text-overflow link"
                                                :to="'/' + alarm.crypto + '/block/' + props.item.hash"
                                            >
                                                {{props.item.blockheight }}
                                            </router-link>
                                        </td>
                                    </tr>
                                </template>
                                <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                                    <span>
                                        {{ pageStart | formatNumber }} - {{ pageStop | formatNumber }} of {{ itemsLength | formatNumber }}
                                    </span>
                                </template>
                            </v-data-table>
                        </div>
                    </div>
                </div>
            </ValidationObserver>

        </template>

        <template #footer="{close, setDisabled}">
            <v-btn
                dark
                outlined
                color="error"
                :disabled="alarmsModalState === modalState.Pending"
                @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                dark
                color="success"
                :loading="alarmsModalState === modalState.Pending"
                outlined
                @click="save(close, setDisabled)"
            >{{detailsEditable ? "Save" : "Ok"}}</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>

import {deepClone, delay, timeDifference} from "@/utils";
import BaseModalDialog from "@/components/common/Base/BaseModalDialog";
import RouteToAddress from "@/components/common/RouteToAddress";
import BlockchainCryptoPicker from "@/components/common/BlockchainCryptoPicker";
import BlockchainCryptoBadge from "@/components/common/BlockchainCryptoBadge";
import BlockchainCryptoPickerSlim from "@/components/common/BlockchainCryptoPickerSlim";
import NotificationPicker from "@/components/alarm/NotificationPicker";
import TypePicker from "@/components/alarm/TypePicker";
import RouteToTransaction from "@/components/common/RouteToTransaction";
import moment from "moment";
import TableDeleteModalDialog from "@/components/common/TableComponents/TableDeleteModalDialog";
import {mapGetters} from "vuex";
import {modalState} from "@/components/common/types/modal";
import ClusterPicker from "@/components/common/ClusterPicker.vue";
import EntityLabel from "@/components/common/EntityLabel.vue";
import NameValueFlex from "@/components/common/NameValueFlex.vue";
import {EntityType} from "@/components/common/types/entity";

export default {
    name: "AlarmDetailModal",
    components: {
        NameValueFlex,
        EntityLabel,
        ClusterPicker,
        TableDeleteModalDialog,
        RouteToTransaction,
        TypePicker,
        NotificationPicker,
        BlockchainCryptoPickerSlim,
        BlockchainCryptoBadge,
        RouteToAddress,
        BaseModalDialog,
        BlockchainCryptoPicker
    },
    data() {
        return {
            nameRules: [v => v !== null && v !== undefined && v.length > 0],
            alarmModel: this.getAlarmModelTemplate(),
            detailsEditable: false,
            headerEditable: false,
            pagination: {page: 1, itemsPerPage: 10},
            currencyNotSet: false,
            hashNotSet: false,
            entityTypes: [EntityType.Address, EntityType.Cluster],
            headers: [
                { align: "center", text: "Time", value: "created_at" },
                { text: "Transaction", align: "center", value: "tx_hash" },
                { align: "center", text: "Block", value: "Blockheight" }
            ],
            closeDialog: null,
            setDisabled: null,
            pickedCluster: null,
            entityType: EntityType.Address
        }
    },
    props: {
        alarm: {
            type: Object,
            required: false,
            default: undefined
        },
        currencies: {
            required: true,
        },
        newAlarmForAddress: {
            type: Boolean,
            required: false,
            default: false,
        },
        newAlarm: {
            type: Boolean,
            required: false,
            default: false
        },
        existingAlarm: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    computed: {
        ...mapGetters(['alarmsModalState']),
        isAlarmTypeAddress() {
            return this.alarmModel.type.toLowerCase() === "address"
        },
        notifications() {
        return this.$store.getters.alarmNotifications;
        },
        notificationsLoading() {
        return this.$store.getters.alarmNotificationsLoading;
        },
        computedNotificationsData()
        {
            if (!this.notifications || this.notifications.length <= 0)
                return []
            return  this.notifications?.data.map(x => {
                return {...x, elapsedCreated: timeDifference(x.created_at)}
            })
        },
        updatedElapsed() {
            return "(" +  timeDifference(this.alarmModel.updated_at) + ")"
        },
        createdElapsed()
        {
            return "(" + timeDifference(this.alarmModel.created_at) + ")"
        },
        modalState() {
            return modalState
        }
    },
    watch:
    {
        alarmModel: {
            handler()
            {
                if (this.alarmModel.crypto !== null)
                {
                    this.currencyNotSet = false
                }
            },
            deep:true
        },
        alarmsModalState(newVal) {
            if (!this.closeDialog)
                return

            if (newVal === modalState.Success) {
                this.closeDialog()
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('ALARMS_MODAL_SET_STATE', modalState.Initial)
            } else if (newVal === modalState.Error)
            {
                this.setDisabled(false)
                this.closeDialog = null
                this.setDisabled = null
                this.$store.commit('ALARMS_MODAL_SET_STATE', modalState.Initial)
            }
        }
    },
    methods: {
        async isFormValid() {
            let isValid = await this.$refs.validator?.validate() && this.alarmModel.tx_direction && this.alarmModel.notify && this.alarmModel.crypto

            if (this.$refs.ClusterPickerRef) {
                isValid = isValid && await this.$refs.ClusterPickerRef.validate()
            }
            return isValid
        },
        handleEntityTypeChange(entity) {
            // handleClusterChange handles "Cluster" case
            if (entity === EntityType.Address) {
                this.alarmModel.type = "address"
            }
        },
        async handleClusterChange(cluster) {
            this.pickedCluster = cluster
            this.alarmModel.hash = cluster?.entity_id
            this.alarmModel.type = cluster?.type === "co-spent" ? "cospent" : "custom"
            // Wait for ClusterPicker value prop to set before validating
            await delay(0)
            await this.$refs.ClusterPickerRef.validate()
        },
        itemsPerPageUpdated()
        {
            this.$store.dispatch("loadAlarmNotifications", { currency: this.alarm.crypto, pagination: {page: this.pagination.page, itemsPerPage: this.pagination.itemsPerPage}, alarmId: this.alarm.id });
        },
        pageUpdated()
        {
            this.$store.dispatch("loadAlarmNotifications", { currency: this.alarm.crypto, pagination: {page: this.pagination.page, itemsPerPage: this.pagination.itemsPerPage}, alarmId: this.alarm.id });
        },
        loadState() {

            this.hashNotSet = false
            this.currencyNotSet = false

            if (this.newAlarmForAddress)
            {
                this.headerEditable = false;
                this.detailsEditable = true;
                this.softReset()
            } else if (this.existingAlarm)
            {
                this.$store.commit('ALARM_NOTIFICATIONS_PAGE_LOADED', [])
                if (this.alarm?.notifications_count > 0)
                {
                    this.$store.dispatch("loadAlarmNotifications", { currency: this.alarm.crypto, pagination: this.pagination, alarmId: this.alarm.id });
                }
                this.headerEditable = false;
                this.detailsEditable = false;
            } else if (this.newAlarm)
            {
                this.alarmModel = this.getAlarmModelTemplate()
                this.headerEditable = true;
                this.detailsEditable = true;
                this.entityType = EntityType.Address
                this.pickedCluster = null
            }

            if (this.alarm)
            {
                this.alarmModel = {...this.alarmModel, ...deepClone(this.alarm)}
                this.entityType = this.isAlarmTypeAddress ? EntityType.Address : EntityType.Cluster
            }
        },
        destroyState()
        {
        },
        isValid()
        {
            return this.alarmModel.hash?.length > 0 &&
                this.alarmModel.name?.length > 0 &&
                this.alarmModel.type?.length > 0 &&
                this.alarmModel.tx_direction?.length > 0 &&
                this.alarmModel.notify?.length > 0 &&
                this.alarmModel.hash?.length > 0 &&
                this.alarmModel.crypto;
        },
        async save(close, setDisabled) {
            if (await this.isFormValid())
            {
                if (this.detailsEditable)
                {
                    this.closeDialog = close
                    this.setDisabled = setDisabled
                    setDisabled(true)
                    this.$emit("save-event", {alarmDetail: this.alarmModel})
                }
            } else
            {
                if (!this.alarmModel.crypto)
                    this.currencyNotSet = true
                if(!this.alarmModel.hash?.length > 0)
                    this.hashNotSet = true
            }
        },
        deleteAlarm()
        {
            this.$emit("delete-event", {itemToDelete: {id: this.alarmModel.id }})
            this.$refs.BaseModalDialogRef.close()
        },
        softReset()
        {
            this.alarmModel.name = ''
            this.alarmModel.notify = 'both'
            this.alarmModel.type = 'address'
            this.entityType = 'address'
            this.pickedCluster = null
            this.alarmModel.tx_direction = 'inout'
            this.alarmModel.email_template = ''
            this.alarmModel.updated_at = moment()
            this.alarmModel.created_at = moment()
        },
        getAlarmModelTemplate(){
            return {
                name: '',
                notify: 'both',
                crypto: null,
                type: 'address',
                tx_direction: 'inout',
                hash: '',
                email_template: '',
                updated_at: moment(),
                created_at: moment(),
                username: this.$store.getters.user.name,
            }
        }
    }
}
</script>

<style scoped>

</style>