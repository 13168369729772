var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-2" }, [
    _vm.addressFundsLoading
      ? _c(
          "div",
          [_c("progress-circular", { attrs: { color: _vm.currency.unit } })],
          1
        )
      : _c(
          "div",
          [
            _vm.addressFunds && _vm.addressFunds.isEmptyCluster
              ? _c("EmptyClusterInfo", {
                  attrs: {
                    address: "",
                    currency: _vm.currency,
                    message:
                      "Fund tracking works only with clusters and this address does not have any!",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.currentCluster !== null && _vm.addressFunds
              ? _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      {
                        attrs: {
                          "no-gutters": "",
                          justify: "start",
                          align: "center",
                        },
                      },
                      [
                        _vm.addressFunds.entities.some((x) =>
                          x.categories.some(
                            (y) =>
                              y.name.toLowerCase() === "gambling" ||
                              y.name.toLowerCase() === "mixer"
                          )
                        )
                          ? _c("v-col", { attrs: { cols: "auto" } }, [
                              _c(
                                "div",
                                { staticClass: "mx-1" },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "white--text",
                                      attrs: { label: "", color: "#b22222" },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v(
                                          "\n                                mdi-alert\n                            "
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                            POSSIBLE MONEY LAUNDERING!\n                        "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.addressFunds.entities.some((x) =>
                          x.categories.some(
                            (y) =>
                              y.name.toLowerCase() === "scam" ||
                              y.name.toLowerCase() === "theft" ||
                              y.name.toLowerCase() === "ransom"
                          )
                        ) && !_vm.filter.isOutgoing
                          ? _c("v-col", { attrs: { cols: "auto" } }, [
                              _c(
                                "div",
                                { staticClass: "mx-1" },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "white--text",
                                      attrs: { label: "", color: "#b22222" },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v(
                                          "\n                                mdi-alert\n                            "
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                            POSSIBLE FRAUD!\n                        "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("exportMenu", {
                              attrs: {
                                "base-url": `${_vm.currency.unit}/cryptocluster/${_vm.currentCluster}/funds`,
                                query: _vm.computeQuery(),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "mt-0" },
                      [
                        _vm.addressFunds &&
                        _vm.addressFunds.highChartsData.length > 0
                          ? _c(
                              "v-col",
                              [
                                _c("fancy-card", {
                                  staticClass: "d-flex justify-center",
                                  attrs: { header: false, "no-gutters": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "body",
                                        fn: function () {
                                          return [
                                            _c("Chart", {
                                              attrs: {
                                                options: _vm.chartOptions,
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3219118772
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (_vm.addressFunds &&
                          _vm.addressFunds.amountsChartsData.length > 0) ||
                        _vm.addressFunds.amountsChartsData != null
                          ? _c(
                              "v-col",
                              [
                                _c("fancy-card", {
                                  staticClass: "d-flex justify-center",
                                  attrs: { header: false, "no-gutters": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "body",
                                        fn: function () {
                                          return [
                                            _c("Chart", {
                                              attrs: {
                                                options:
                                                  _vm.amountsChartOptions,
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3858284907
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (_vm.addressFunds &&
                          _vm.addressFunds.categoryAppearanceData.length > 0) ||
                        _vm.addressFunds.categoryAppearanceData != null
                          ? _c("v-col", [
                              !_vm.isLogaritmic
                                ? _c(
                                    "div",
                                    [
                                      _c("fancy-card", {
                                        staticClass: "d-flex justify-center",
                                        attrs: {
                                          header: false,
                                          "no-gutters": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "body",
                                              fn: function () {
                                                return [
                                                  _c("Chart", {
                                                    attrs: {
                                                      options:
                                                        _vm.categoryAppearanceOptions,
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1747786518
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("fancy-card", {
                                        staticClass: "d-flex justify-center",
                                        attrs: {
                                          header: false,
                                          "no-gutters": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "body",
                                              fn: function () {
                                                return [
                                                  _c("Chart", {
                                                    attrs: {
                                                      options:
                                                        _vm.categoryAppearanceOptionsLogaritmic,
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3782259803
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex justify-start" }, [
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(
                              "\n                        Cumulatively " +
                                _vm._s(
                                  _vm.filter.isOutgoing ? "Sent" : "Received"
                                ) +
                                ":\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("fiat-convertor", {
                            attrs: {
                              value: Number(_vm.addressFunds.total_amount),
                              fiats: _vm.addressFunds.fiats,
                              "convert-from-satoshi": "",
                              "with-unit": "",
                              monospace: "",
                              "unit-small": "",
                              "unit-light": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "mb-3" }),
                    _vm._v(" "),
                    _c("fancy-card", {
                      attrs: { header: false, "no-gutters": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c("v-data-table", {
                                  staticClass: "elevation-0",
                                  staticStyle: { "white-space": "nowrap" },
                                  attrs: {
                                    dense: "",
                                    loading: _vm.addressFundsLoading,
                                    headers: _vm.headers,
                                    items: _vm.computedFunds,
                                    "items-per-page": 30,
                                    "footer-props": {
                                      "items-per-page-options": [30, 50, 200],
                                      "items-per-page-text":
                                        "Clusters per page",
                                      "show-current-page": true,
                                      showFirstLastPage: true,
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm._l(_vm.headers, function (h) {
                                        return {
                                          key: `header.${h.value}`,
                                          fn: function ({ header }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  key: h.value,
                                                  staticClass:
                                                    "d-inline-flex align-center",
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(header.text) +
                                                        "\n                                "
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  header.customFilter
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c("FilterIcon", {
                                                            attrs: {
                                                              componentFilterType:
                                                                header
                                                                  .customFilter
                                                                  .component,
                                                              name: header.value,
                                                              append:
                                                                header
                                                                  .customFilter
                                                                  .append,
                                                              "explicit-apply":
                                                                "",
                                                              additionalOptions:
                                                                header
                                                                  .customFilter
                                                                  .additionalOptions,
                                                              filterSubType:
                                                                header
                                                                  .customFilter
                                                                  .filterSubType,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .resultsFilter[
                                                                  header
                                                                    .customFilter
                                                                    .override
                                                                    ? header
                                                                        .customFilter
                                                                        .override
                                                                    : header.value
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.resultsFilter,
                                                                    header
                                                                      .customFilter
                                                                      .override
                                                                      ? header
                                                                          .customFilter
                                                                          .override
                                                                      : header.value,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "resultsFilter[header.customFilter.override ? header.customFilter.override : header.value]",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          },
                                        }
                                      }),
                                      {
                                        key: "item",
                                        fn: function ({ item }) {
                                          return [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                [
                                                  _c("EntityLabel", {
                                                    attrs: {
                                                      entity: item.entityid,
                                                      entityLabel: item.label,
                                                      owner: item.owner,
                                                      color: item.categories[0]
                                                        ? item.categories[0]
                                                            .color
                                                        : undefined,
                                                      isCopyable: true,
                                                      showExtra: false,
                                                      actAsLink: true,
                                                      currency:
                                                        _vm.currency.unit,
                                                      "is-address": false,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                _vm._l(
                                                  item.categories,
                                                  function (cat) {
                                                    return _c(
                                                      "AddressCategory",
                                                      {
                                                        key: cat.id,
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          iconItem: cat,
                                                          name: cat.name,
                                                          color: cat.color,
                                                        },
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                    staticStyle: {
                                                      margin: "2px 0",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-right align-center",
                                                        staticStyle: {
                                                          width: "40%",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fontMonospace",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  item.transfers_count
                                                                ) +
                                                                "\n                                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ml-2 mr-1",
                                                      },
                                                      [
                                                        !_vm.isOnSplitView ||
                                                        _vm.$route.params
                                                          .graphId
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "router-link",
                                                                  {
                                                                    staticClass:
                                                                      "text-decoration-none",
                                                                    attrs: {
                                                                      to: _vm.createFundsLocation(
                                                                        item.entityid
                                                                      ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          top: "",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activator",
                                                                                fn: function ({
                                                                                  on,
                                                                                  attrs,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                height:
                                                                                                  "24px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "#616161",
                                                                                                small:
                                                                                                  "",
                                                                                                outlined:
                                                                                                  "",
                                                                                                label:
                                                                                                  "",
                                                                                              },
                                                                                          },
                                                                                          "v-btn",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "timeline"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Plot Graph"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      top: "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                              attrs,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            height:
                                                                                              "24px",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "#616161",
                                                                                            small:
                                                                                              "",
                                                                                            outlined:
                                                                                              "",
                                                                                            label:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.fetchFunds(
                                                                                                item.entityid
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      "v-btn",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "timeline"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Load Graph"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "50%",
                                                          float: "left",
                                                          "text-align": "left",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "RouteToInteractions",
                                                          {
                                                            attrs: {
                                                              "no-decoration":
                                                                "",
                                                              query:
                                                                "?remote=" +
                                                                item.entityid +
                                                                "&isOutgoing=" +
                                                                _vm.filter
                                                                  .isOutgoing +
                                                                "&maxHops=" +
                                                                item.transfer_max_hops,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "text" },
                                                              [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      top: "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                              attrs,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-decoration-none",
                                                                                        staticStyle:
                                                                                          {
                                                                                            height:
                                                                                              "24px",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "#616161",
                                                                                            small:
                                                                                              "",
                                                                                            outlined:
                                                                                              "",
                                                                                            label:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      "v-btn",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "font-size":
                                                                                              "22px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-format-list-bulleted"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Show Interactions"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(item.transfer_min_hops)
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(item.transfer_max_hops)
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      item.transfer_avg_hops,
                                                      2
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(item.link_factor)
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c("fiat-convertor", {
                                                    attrs: {
                                                      value: item.amount,
                                                      fiats:
                                                        _vm.addressFunds.fiats,
                                                      "with-unit": "",
                                                      "convert-from-satoshi":
                                                        "",
                                                      monospace: "",
                                                      "unit-light": "",
                                                      "unit-small": "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: "footer.page-text",
                                        fn: function ({
                                          pageStart,
                                          pageStop,
                                          itemsLength,
                                        }) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      pageStart
                                                    )
                                                  ) +
                                                  " - " +
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      pageStop
                                                    )
                                                  ) +
                                                  " of " +
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      itemsLength
                                                    )
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3049963083
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }