var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(_setup.GenericPickerWithDisplay, {
            attrs: {
              "get-items": _setup.getItems,
              headers: _setup.headers,
              items: _setup.items,
              loading: _setup.loading,
              "item-value": "id",
              item_id: "id",
              multiple: "",
              table: "",
            },
            scopedSlots: _vm._u([
              {
                key: "hint",
                fn: function () {
                  return [
                    _vm._v("\n                Search in "),
                    _c("i", [_vm._v("all")]),
                    _vm._v(" graphs\n            "),
                  ]
                },
                proxy: true,
              },
              {
                key: "pickerItem",
                fn: function ({ item }) {
                  return [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v(
                        "\n                    timeline\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", {
                          domProps: { textContent: _vm._s(item.name) },
                        }),
                        _vm._v(" "),
                        _c("v-list-item-subtitle", [
                          _c("strong", [_vm._v(_vm._s(item.crypto))]),
                          _vm._v(" graph |\n                        Configs: "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm._f("formatNumber")(item.count))),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "tableItem",
                fn: function ({ item }) {
                  return [
                    _c("td", { staticClass: "text-left dynamicTruncate" }, [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c(_setup.BlockchainCryptoBadge, {
                          attrs: { crypto: item.crypto, iconWidth: 24 },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "text-right",
                        staticStyle: {
                          "text-align": "center",
                          "vertical-align": "middle",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("formatNumber")(item.count)) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "text-overflow",
                        staticStyle: {
                          "text-align": "center",
                          "vertical-align": "middle",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("moment")(
                                item.created_at,
                                "YYYY-MM-DD HH:mm:ss Z"
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "text-overflow",
                        staticStyle: {
                          "text-align": "center",
                          "vertical-align": "middle",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("moment")(
                                item.updated_at,
                                "YYYY-MM-DD HH:mm:ss Z"
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _setup.itemsToAdd,
              callback: function ($$v) {
                _setup.itemsToAdd = $$v
              },
              expression: "itemsToAdd",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }