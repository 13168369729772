<template>
    <v-autocomplete
        :items="owners"
        :search-input.sync="search"

        item-text="name"
        item-value="id"
        label="search owners"
        placeholder="Start typing to search Owners"

        multiple
        hide-no-data
        chips
        small-chips
        return-object
        clearable
        :disabled="disabled"

        v-bind="$attrs"
        v-on="$listeners"
    >
        <template v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                @click="data.select"
            >
                {{ data.item.name }}
            </v-chip>
        </template>
    </v-autocomplete>
</template>

<script>

export default {
    name: 'OwnerPicker',
    props: {
        owners: {
            type: Array,
            validator: prop => prop.every(i => i.hasOwnProperty("id") && i.hasOwnProperty("name")),
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            search: ""
        }
    },
    watch: {
        search(value) {
            if (!value) {
                return
            }
            //Debounce
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.ownerFilterChanged(value)
            }, 350)
        }
    },
    methods: {
        ownerFilterChanged(filter) {
            this.$emit("owner-filter-changed-event", { filter })
        },
    }
}
</script>