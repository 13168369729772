<script setup>
import {computed, ref} from "vue";
import {useGetData} from "@/components/common/Composables/useGetData";
import GenericPickerWithDisplay from "@/components/common/Pickers/GenericPickerWithDisplay.vue";
import {BlockchainCryptoBadge} from "@/components/common";
import {DataState} from "@/components/common/types/data";

const itemsToAdd = ref([])

defineExpose({itemsToAdd})

const pagination = {
    page: 1,
    itemsPerPage: 20,
    sortBy: ["updated_at"],
    sortDesc: [true],
}

const {dataState, getData, data} = useGetData("loadGraphs", {
    pagination: {
        ...pagination
    }
}, false)

const items = computed(() => data.value?.data ?? [])
const loading = computed(() => dataState.value === DataState.Loading)

const getItems = (searchString) => {
    getData({pagination: {...pagination, filterName: searchString}})
}

const headers = [
    {text: 'Name', align: 'center', value: 'name', sortable: false, width: '30%'},
    {text: 'Currency', align: 'center', value: 'crypto', sortable: false},
    {text: '# Configs', align: 'right', value: 'count', sortable: false},
    {text: 'Created', align: 'center', value: 'created_at', sortable: false},
    {text: 'Updated', align: 'center', value: 'updated_at', sortable: false},
]
</script>


<template>
    <v-row>
        <v-col cols="12">
            <GenericPickerWithDisplay
                v-model="itemsToAdd"
                :get-items="getItems"
                :headers="headers"
                :items="items"
                :loading="loading"
                item-value="id"
                item_id="id"
                multiple
                table>
                <template #hint>
                    Search in <i>all</i> graphs
                </template>
                <template #pickerItem="{ item }">
                    <v-icon left>
                        timeline
                    </v-icon>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.name">
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <strong>{{ item.crypto }}</strong> graph |
                            Configs: <strong>{{ item.count | formatNumber }}</strong>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </template>
                <template #tableItem="{ item }">
                    <td class="text-left dynamicTruncate">
                        <span>{{ item.name }}</span>
                    </td>
                    <td>
                        <BlockchainCryptoBadge
                            :crypto="item.crypto"
                            :iconWidth="24"
                        />
                    </td>
                    <td class="text-right" style="text-align: center; vertical-align: middle;">
                        {{ item.count | formatNumber }}
                    </td>
                    <td
                        class="text-overflow"
                        style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                    >
                        <span>{{
                                item.created_at
                                    | moment("YYYY-MM-DD HH:mm:ss Z")
                            }}</span>
                    </td>
                    <td
                        class="text-overflow"
                        style="
                                        text-align: center;
                                        vertical-align: middle;
                                    "
                    >
                        <span>{{
                                item.updated_at
                                    | moment("YYYY-MM-DD HH:mm:ss Z")
                            }}</span>
                    </td>
                </template>
            </GenericPickerWithDisplay>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>