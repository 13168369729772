<template>
    <BaseModalDialog
        :title="'Edit All Cluster Metadata'"
        :loading="loading"
        width="60%"
    >
        <template #showbutton="{ on }">
            <slot name="showbutton" :on="on" />
        </template>
        <template #content>
            <ValidationObserver ref="validator" mode="lazy">
                <v-card elevation="0">
                    <v-row class="ma-2">
                        <v-col cols="4" class="py-2">
                            <v-text-field-validateable
                                disabled
                                rules="required|crypto"
                                label="Entity ID"
                                v-model="clusterMetadataDetail.cluster"
                                outlined
                                hide-details
                            />
                        </v-col>

                        <v-col cols="3" class="py-2">
                            <BlockchainCryptoPicker
                                disabled
                                :currencies="currencies"
                                v-model="crypto"
                                :value="crypto"
                                rules="required"
                                hide-details
                            />
                        </v-col>

                        <v-col cols="2" class="py-2">
                            <v-text-field
                                disabled
                                label="Number of Addresses"
                                :value="clusterMetadataDetail.totalAddresses | formatNumber"
                                outlined
                                hide-details
                            />
                        </v-col>

                        <v-col cols="3" class="py-2">
                            <v-text-field
                                disabled
                                label="Created At"
                                :value="clusterMetadataDetail.created_at | moment('YYYY-MM-DD HH:mm:ss Z')"
                                outlined
                                hide-details
                            />
                        </v-col>
                    </v-row>

                    <v-row class="ma-2">
                        <v-col class="py-2">
                            <CategoryPicker
                                :categories="categories"
                                v-model="clusterMetadataDetail.categoryIds"
                                hide-details
                            />
                        </v-col>
                        <v-col class="py-2" id="disabled-owner-text-field">
                            <v-select
                                disabled
                                label="Owners"
                                item-text="name"
                                item-value="id"
                                :items="clusterMetadataDetail.owners"
                                v-model="clusterMetadataDetail.owners"
                                outlined
                                multiple
                                hide-details
                            />
                        </v-col>
                        <v-col class="py-2">
                            <OwnerPicker
                                :owners="owners"
                                v-model="clusterMetadataDetail.owners"
                                :loading="isOwnersLoading"
                                @owner-filter-changed-event="ownerFilterChanged"
                                outlined
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-card>
            </ValidationObserver>
        </template>
        <template #footer="{close}">
            <v-btn color="error" text @click="close">Cancel</v-btn>
            <v-spacer />
            <v-btn
                color="success"
                text
                @click="save(close)"
            >{{ "Save Cluster Metadata" }}</v-btn>
        </template>
    </BaseModalDialog>
</template>

<script>
import {
    BaseModalDialog,
    BlockchainCryptoPicker,
    OwnerPicker,
    CategoryPicker,
} from "@/components/common"
import {mapGetters} from "vuex";



export default {
        name: 'AllClusterMetadataDetailModal',
    components: {
        BaseModalDialog,
        BlockchainCryptoPicker,
        OwnerPicker,
        CategoryPicker,
    },
    props: {
        owners: {
            type: Array,
            default: () => []
        },
        crypto: {
            type: String,
            default: 'BTC',
        },
        categories: {
            type: Array,
            default: () => []
        },
        clusterMetadataDetail: {
            type: Object
        },
        currencies: {
            type: Array,
            default: () => []
        },
        isOwnersLoading: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
        },
    },

    watch: {
        "clusterMetadataDetail.owner"(newValue) {
            this.clusterMetadataDetail.owner_id = newValue?.id
        },

    },
    computed: {
        ...mapGetters([
            "isNeoClusterDetailPaginationLoading",
        ]),

    },
    methods: {
        async save(close) {
            const isValid = await this.$refs.validator.validate();
            if (isValid) {
                this.$emit("save-event", { currency: this.crypto, clusterMetadataDetail: this.clusterMetadataDetail })
                close()
            }
        },

        ownerFilterChanged({ filter }) {
            this.$emit("owner-filter-changed-event", { filter })
        }
    }
}
</script>