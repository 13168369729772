var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-y height100" },
    [
      _c(
        "v-container",
        [
          _vm.loading
            ? _c(
                "div",
                [
                  _c("ProgressCircular", {
                    staticStyle: { "padding-top": "200px" },
                  }),
                ],
                1
              )
            : !_vm.results
            ? _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    "fill-height": "",
                    "justify-center": "",
                  },
                },
                [
                  _c("h2", { staticClass: "font-weight-light" }, [
                    _vm._v(
                      "Can not found any results for " +
                        _vm._s(_vm.$route.query.q)
                    ),
                  ]),
                ]
              )
            : _c(
                "div",
                [
                  _c("h1", [_vm._v("Search Results")]),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    {
                      staticClass: "transparent border mt-3",
                      attrs: { elevation: "0" },
                    },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            "slider-size": "5",
                            "background-color": _vm.currency
                              ? _vm.currency.unit + ""
                              : "secondary",
                            dark: "",
                            "slider-color": "white",
                          },
                          model: {
                            value: _vm.selectedTab,
                            callback: function ($$v) {
                              _vm.selectedTab = $$v
                            },
                            expression: "selectedTab",
                          },
                        },
                        [
                          _vm._l(_vm.categories, function (category) {
                            return _c(
                              "v-tab",
                              { key: category.title, staticClass: "mx-2" },
                              [
                                _c("v-badge", { attrs: { color: "red" } }, [
                                  _c(
                                    "span",
                                    { attrs: { slot: "badge" }, slot: "badge" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.results[category.key].length)
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(category.title) +
                                      "\n                        "
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            [
                              _c("InfoText", [
                                _vm._v(
                                  "\n                            Addresses successfully parsed as proper ones supported\n                            system (e.g., Bitcoin) based on\n                            regular expression.\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { attrs: { text: "" } },
                                [
                                  _c("v-data-table", {
                                    staticClass: "elevation-0",
                                    attrs: {
                                      headers: _vm.validHeaders,
                                      items: _vm.results.valid,
                                      "items-per-page": -1,
                                      "hide-default-footer": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function (props) {
                                          return [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "text-left" },
                                                [
                                                  _c("RouteToAddress", {
                                                    attrs: {
                                                      address:
                                                        props.item.address,
                                                      currency:
                                                        props.item.crypto,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "text",
                                                          fn: function () {
                                                            return [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    props.item
                                                                      .address
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c("img", {
                                                  staticClass: "middle mr-2",
                                                  attrs: {
                                                    src: _vm._f(
                                                      "createImageUrl"
                                                    )(
                                                      "cryptoIcons/color/" +
                                                        props.item.crypto.toLowerCase() +
                                                        ".svg"
                                                    ),
                                                  },
                                                }),
                                              ]),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            [
                              _c("InfoText", [
                                _vm._v(
                                  "\n                            Valid addresses that have some transactions in the blockchain, beaware of forked\n                            projects\n                            (e.g., Bitcoin and Bitcoincash)\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { attrs: { text: "" } },
                                [
                                  _c("v-data-table", {
                                    staticClass: "elevation-0",
                                    attrs: {
                                      headers: _vm.recognizedHeaders,
                                      items: _vm.results.recognized,
                                      "items-per-page": -1,
                                      "hide-default-footer": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function (props) {
                                          return [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "text-left" },
                                                [
                                                  _c("RouteToAddress", {
                                                    attrs: {
                                                      address:
                                                        props.item.address,
                                                      currency:
                                                        props.item.crypto,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "text",
                                                          fn: function () {
                                                            return [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    props.item
                                                                      .address
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-overflow",
                                                },
                                                [
                                                  _c("BlockchainCryptoBadge", {
                                                    attrs: {
                                                      crypto: props.item.crypto,
                                                      currencies:
                                                        _vm.currencies,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                [
                                                  _c("fiat-convertor", {
                                                    attrs: {
                                                      fiats:
                                                        _vm.results.fiats[
                                                          props.item.crypto
                                                        ],
                                                      value: props.item.balance,
                                                      monospace: "",
                                                      "with-unit": "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "fontMonospace text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.txcount)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass: "link",
                                                      attrs: {
                                                        to: `/${props.item.crypto}/transaction/${props.item.lasttxid}/`,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("moment")(
                                                            props.item
                                                              .lastblocktime,
                                                            "YYYY-MM-DD HH:mm:ss Z"
                                                          )
                                                        ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "link fontMonospace",
                                                      attrs: {
                                                        to: `/${props.item.crypto}/block/${props.item.lastblockheight}/`,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatNumber"
                                                          )(
                                                            props.item
                                                              .lastblockheight
                                                          )
                                                        ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.identity)
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            [
                              _c("InfoText", [
                                _vm._v(
                                  "\n                            Valid addresses which do not have any transactions in any blockchain (i.e., unused\n                            addresses)\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { attrs: { text: "" } },
                                [
                                  _c("v-data-table", {
                                    staticClass: "elevation-0",
                                    attrs: {
                                      headers: _vm.validHeaders,
                                      items: _vm.results.unrecognized,
                                      "items-per-page": -1,
                                      "hide-default-footer": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function (props) {
                                          return [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                [
                                                  _c("RouteToAddress", {
                                                    attrs: {
                                                      address:
                                                        props.item.address,
                                                      currency:
                                                        props.item.crypto,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "text",
                                                          fn: function () {
                                                            return [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    props.item
                                                                      .address
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c("img", {
                                                  staticClass: "middle mr-2",
                                                  attrs: {
                                                    src: _vm._f(
                                                      "createImageUrl"
                                                    )(
                                                      "cryptoIcons/color/" +
                                                        props.item.crypto.toLowerCase() +
                                                        ".svg"
                                                    ),
                                                  },
                                                }),
                                              ]),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            [
                              _c("InfoText", [
                                _vm._v(
                                  "\n                            Addresses unsuccessfully matched against regular expressions present in our system,\n                            maybe\n                            from other cryptocurrencies\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { attrs: { text: "" } },
                                [
                                  _c("v-data-table", {
                                    staticClass: "elevation-0",
                                    attrs: {
                                      headers: _vm.invalidHeaders,
                                      items: _vm.results.invalid,
                                      "items-per-page": -1,
                                      "hide-default-footer": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function (props) {
                                          return [
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(props.item.address)
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            [
                              _c("InfoText", [
                                _vm._v(
                                  "\n                            All inputs passed to search textarea field\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { attrs: { text: "" } },
                                [
                                  _c("v-data-table", {
                                    staticClass: "elevation-0",
                                    attrs: {
                                      headers: _vm.allinputHeaders,
                                      items: _vm.results.addrs,
                                      "items-per-page": -1,
                                      "hide-default-footer": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function (props) {
                                          return [
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(_vm._s(props.item)),
                                              ]),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }