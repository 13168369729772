<template>
    <div style="max-width: 272px">
        <FancyCard class="mt-2" >
            <template #header>
                <div class="d-flex align-center">
                    <slot name="titleIcon"></slot>
                    {{ title }}
                </div>
            </template>
            <template #body>
                <div v-if="loading" :style="['min-width: 0',  ]">
                    <v-skeleton-loader class="skeletonLoader" max-width="100%" width="230" min-width="0" height="138" loading type="image"/>
                </div>
                <div v-else-if="gaugeOptions">
                    <Chart :options="gaugeOptions"/>
                </div>
            </template>
        </FancyCard>
    </div>
</template>

<script setup>
import {Chart} from 'highcharts-vue'
import Highcharts from "highcharts"
import Vue, {computed, ref, watch} from "vue"
import store from "@/store";
import FancyCard from "@/components/common/Cards/FancyCard";
import moment from "moment";
import HighchartsMore from 'highcharts/highcharts-more'

HighchartsMore(Highcharts)

const props = defineProps({
    loading: {
        type: Boolean
    },
    value: {
        default: 100,
        type: Number
    },
    title: {
        required: false,
        type: String,
        default: ""
    },
    titleIcon: {
        type: String,
        required: false,
        default: ""
    },
    thresholds: {
        type: Object,
    },
    subtitle: {
        type: String,
        required: false,
        default: ""
    },
    blockTime: {
        type: Number,
    },
    graphUnit: {
        type: String,
    }
})

const currency = computed(() => store.getters.currency)
const headers = computed(() => store.getters.dashboardData[currency.value.unit].status.headers)

const labelUnit = ref(props.graphUnit)

const graphUnitWatchable = computed(() => props.graphUnit)

watch(graphUnitWatchable, (newValue) => {
    labelUnit.value = newValue
})

const label = computed(() => {
    const { value, unit } = Vue.filter('roundTimeToHighestTimeUnit')(moment().unix() - props.blockTime)
    return labelUnit.value === "Blocks" ? {value: Math.abs(props.value - headers.value), text: "blocks"}
        : {value: value, text: unit}
})

const handleLabelClick = () => {
    labelUnit.value = labelUnit.value === "Blocks" ? "Time" : "Blocks"
}

const dataLabelFormat = '<div style="cursor: pointer; text-align:center; width: 100px; height: 60px">' +
    '<span style="font-size:25px">{point.labelValue.value}</span><br/>' +
    '<span style="font-size:14px;opacity:0.6">{point.labelValue.text}</span>' +
    '<span style="font-size:12px; opacity:0.6"> behind</span>' +
    '</div>'
const gaugeOptions = computed(() => ({
    chart: {
        type: 'gauge',
        height: '60%',
        width: 230,
        events: {
            load: function () {
                const chart = this;
                chart.series[0].data.forEach(function (point) {
                    point.dataLabel.on('click', handleLabelClick)
                })
            },
            click: function () {
                this.series.format = dataLabelFormat
            }
        }
    },

    title: {
        text: null
    },

    pane: {
        center: ['50%', '100%'],
        size: '190%',
        startAngle: -90,
        endAngle: 90,
        background: {
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'rgb(238,238,238)',
            innerRadius: '59%',
            outerRadius: '100%',
            shape: 'arc'
        }
    },

    exporting: {
        enabled: false
    },

    tooltip: {
        enabled: false
    },

    // the value axis
    yAxis: {
        min: props.thresholds.error,
        max: headers.value,
        labels: {
            enabled: false
        },
        plotBands: [{
            thickness: 45,
            from: props.thresholds.error,
            to: props.thresholds.critical,
            color: props.value < props.thresholds.critical ? '#FF5252' : 'rgb(225,225,225)' // red
        }, {
            thickness: 45,
            from: props.thresholds.critical,
            to: props.thresholds.warning,
            color: (props.value >= props.thresholds.critical && props.value < props.thresholds.warning) ? '#FCE83A' : 'rgb(225,225,225)' // yellow
        }, {
            thickness: 45,
            from: props.thresholds.warning,
            to: props.thresholds.bestcase,
            color: (props.value >= props.thresholds.warning && props.value <= props.thresholds.bestcase) ? '#4CAF50' : 'rgb(225,225,225)' // green
        }, {
            thickness: 45,
            from: props.thresholds.bestcase,
            to: headers.value,
            color: '#333333' // green
        }],
        tickAmount: 3,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 45,
        tickColor: '#f6f6f6',
        tickPositions: [props.thresholds.critical, props.thresholds.warning, props.thresholds.bestcase],
        lineWidth: 1,
        minorTickInterval: null,
    },

    plotOptions: {
        gauge: {
            dataLabels: {
                crop: false,
                borderWidth: 0,
                useHTML: true
            },
            dial: {
                radius: '100%',
                backgroundColor: 'black',
                borderColor: '#ffffff96',
                borderWidth: 1,
                baseWidth: 4,
                topWidth: 1,
                baseLength: '60%', // of radius
                rearLength: '-50%'
            },
            pivot: {
                radius: 0
            }
        }
    },
    credits: {
        enabled: false
    },
    series: [{
        name: 'Speed',
        data: [{
            y: props.value < props.thresholds.error ? props.thresholds.error : props.value,
            labelValue: label.value,
        }],
        dataLabels: {
            format: dataLabelFormat,
        },
        tooltip: {
            valueSuffix: '%'
        }
    }]
}))
</script>

<style scoped>

</style>