var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-autocomplete",
    _vm._g(
      _vm._b(
        {
          attrs: {
            items: _vm.owners,
            "search-input": _vm.search,
            "item-text": "name",
            "item-value": "id",
            label: "search owners",
            placeholder: "Start typing to search Owners",
            multiple: "",
            "hide-no-data": "",
            chips: "",
            "small-chips": "",
            "return-object": "",
            clearable: "",
            disabled: _vm.disabled,
          },
          on: {
            "update:searchInput": function ($event) {
              _vm.search = $event
            },
            "update:search-input": function ($event) {
              _vm.search = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "selection",
              fn: function (data) {
                return [
                  _c(
                    "v-chip",
                    _vm._b(
                      {
                        attrs: { "input-value": data.selected },
                        on: { click: data.select },
                      },
                      "v-chip",
                      data.attrs,
                      false
                    ),
                    [
                      _vm._v(
                        "\n            " + _vm._s(data.item.name) + "\n        "
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        "v-autocomplete",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }